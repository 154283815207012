<template>
    <b-container class="py-5">
        <h5 class="text-white">{{t('website.allCategories')}}</h5>
        <section v-for="(cat, i) in webCategories" :key="i" class="category-section">
            <b-row>
                <b-col
                    cols="12"
                    lg="7"
                    class="order-1"
                    :class="i % 2 != 0 ? 'order-lg-0' : 'order-lg-1'"
                >
                    <div
                        class="category-images-container py-5 mx-auto"
                        :class="[i == 0 ? 'pt-0' : '', i == 3 ? 'pb-0' : '']"
                        style="max-width: 700px"
                    >
                        <div class="image-2"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                        >
                            <div class="category-image-card-border rounded">
                                <div class="category-image-card rounded">
                                    <div class="sub-category rounded">
                                    </div>
                                </div>
                            </div>
                            <div class="category-content d-flex flex-column align-items-center justify-content-center position-absolute" style="top: 0; left: 0;">
                                <h4 class="text-white my-5 pt-5">{{cat.name}}</h4>
                                <div class="w-100 h-100 text-center" style="margin-bottom: -40px;">
                                    <b-row>
                                        <b-col cols="12" lg="4" v-for="(subCat, i) in cat.childImages.slice(0, 3)" :key="i" class="mb-3">
                                            <div style="max-width: 300px; min-height: 200px" class="mx-auto h-100 d-flex align-items-end justify-content-center"
                                                data-aos="fade-up"
                                                :data-aos-duration="1000 * (i + 1)"
                                            >
                                                <img :src="$store.getters['app/domainHost'] + '/' + subCat" :width="+'140' + i * 20" class="rounded" style="object-fit: cover;">
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <b-button class="mx-auto text-custom-blue-sky" :to="'/category/' + cat.id" variant="flat-secondary" :style="i % 2 != 0 ? 'transform: skewX(-1deg) scaleX(-1)' : 'transform: skewX(1deg)'">
                                        {{t('website.seeMore')}}
                                        <unicon name="arrow-left" fill="var(--custom-blue-sky)" class="arrow"/>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col
                    cols="12"
                    lg="5"
                    class="order-0 d-flex align-items-center"
                    :class="i % 2 != 0 ? 'order-lg-1' : 'order-lg-0'"
                >
                    <div
                        data-aos="fade-up"
                        data-aos-duration="3000"
                        class="category-description-container text-center w-100 rounded mx-auto"
                        style="max-width: 300px"
                    >
                        <img :src="$store.getters['app/domainHost'] + '/' + cat.imagePath"
                            class="w-100 rounded">
                    </div>
                </b-col>
            </b-row>
        </section>
    </b-container>
</template>

<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { mapActions, mapState } from 'vuex'
import { handleLangChange } from '@/libs/event-bus';
export default {
    computed: {
        ...mapState({
            webCategories: state => state.webProducts.webCategories
        })
    },
    created() {
        this.getCategoriesWebsite();
        handleLangChange(this.getCategoriesWebsite);
    },
    metaInfo() {
        return {
            titleTemplate: "Awija - " + this.$t("website.categories"),
            htmlAttrs: {
                lang: this.$i18n.locale
            },
            meta: [
                {
                    name: "description",
                    content: "description"
                },
                {
                    name: "keywords",
                    content: "keywords, keywords, keywords, ..."
                }
            ]
        };
    },
    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    methods: {
        ...mapActions(['getCategoriesWebsite']),
    }
}
</script>

<style scoped lang="scss">
.category-section {
    padding: 50px 0;
    color: #fff;
    .category-images-container {
        min-height: 400px;
        height: 100%;
        position: relative;
        .image-2 {
            perspective: 500px;
            transform-style: preserve-3d;
            position: relative;
            height: 100%;
            width: 100%;
            .category-image-card-border {
                transform-origin: right center;
                transform: scale(1) translate3d(0, 0, -62px) rotate3d(22, 1, 0, -8deg) skew(-15deg, 0deg);
                padding: 3px;
                height: 100%;
                width: 100%;
                background: linear-gradient(0deg, #878787, #ffffff, #6d6d6d, #ffffff, #949494);
                .category-image-card {
                    height: 100%;
                    width: 100%;
                }
            }
            .category-content {
                width: 100%;
                height: 100%;
            }
        }
        .sub-category {
            background: var(--custom-dark);
            height: 100%;
        }
    }
    .category-description-container {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            width: 200px;
            height: 20px;
            background: rgba(0,0,0,.6);
            filter: blur(8px);
            border-radius: 50%;
        }
    }
    &:nth-of-type(2n) {
        .category-images-container {
            .image-2 {
                .category-image-card-border {
                    position: relative;
                    transform-origin: left center;
                    transform: scale(1) translate3d(0,0px,-62px) rotate3d(22, 1, 0, 8deg) skew(15deg, 0deg)
                }
            }
            .category-content {
                transform: scaleX(-1);
                h4 {
                    transform: scaleX(-1);
                }
            }
        }
    }
    @media screen and (max-width: 992px) {
         .category-images-container {
            .image-2 {
                .category-image-card-border {
                    transform: rotate(0deg)!important;
                }
            }
         }
    }
}
[dir="ltr"] {
    .category-section {
        .category-images-container {
            .image-2 {
                .category-image-card-border {
                    transform: scale(1) translate3d(0, 0, -62px) rotate3d(22, 1, 0, 8deg) skew(-15deg, 0deg);
                }
            }
        }
        .category-description-container {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                bottom: -16px;
                left: 50%;
                transform: translateX(-50%);
                width: 200px;
                height: 20px;
                background: rgba(0,0,0,.6);
                filter: blur(8px);
                border-radius: 50%;
            }
        }
        &:nth-of-type(2n) {
            .category-images-container {
                .image-2 {
                    .category-image-card-border {
                        position: relative;
                        transform-origin: left center;
                        transform: scale(1) translate3d(0,0px,-62px) rotate3d(22, 1, 0, 8deg) skew(15deg, 0deg)
                    }
                }
                .category-content {
                    transform: scaleX(-1);
                    h4 {
                        transform: scaleX(-1);
                    }
                }
            }
        }
    }
    .arrow {
        transform: scaleX(-1);
    }
}
</style>